import React from "react";
import AOS from "aos";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Brand from "../components/Mainpage/Brands/brands";
import Carousel from "../components/Mainpage/Carousel/carousel";
import Client from "../components/Mainpage/Clients/clients";
import About from "../components/Mainpage/About/about";
import Contact from "../components/Mainpage/Contact/contact";
import Showcase from "../components/Mainpage/Showcase/showcase";
import Feature from "../components/Mainpage/Feature/feature";

class IndexPage extends React.Component {
    componentDidMount() {
        this.aos = AOS;
        this.aos.init({
            duration: 1000,
            easing: "ease-in-out",
            once: true,
            mirror: false,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.aos.refresh();
    }

    render() {
        return (
            <Layout>
                <SEO title="Beranda" />
                <Carousel />
                <About />
                <Client />
                <Feature />
                <Showcase />
                <Brand />
                {/*TODO: Minta diremove dulu*/}
                {/*<Price />*/}
                {/*TODO: Fix the FAQ section*/}
                {/*<Faq />*/}
                <Contact />
            </Layout>
        );
    }
}

export default IndexPage;
