import React from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import "../../../styles/about.css";

const About = () => {
    return (
        <section id="about" className="about">
            <div className="container">
                <div className="section-title" data-aos="fade-up">
                    <h2>Tentang kami</h2>
                </div>
                <div className="row content">
                    <div
                        className="col-lg-6"
                        data-aos={"fade-up"}
                        data-aos-delay={"150"}
                    >
                        <p>
                            Mengapa anda harus memilih kami sebagai mitra dalam
                            kebutuhan pertunjukan publik maupun periklanan
                            videotron anda?
                        </p>
                        <ul>
                            <li>
                                <FontAwesomeIcon icon={faCheck} />
                                <p>
                                    Berpengalaman lebih dari 4 tahun dengan
                                    klien yang tersebar di nusantara.
                                </p>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheck} />
                                <p>Produk yang bergaransi 3 tahun.</p>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheck} />
                                <p>Kualitas produk terjamin.</p>
                            </li>
                        </ul>
                    </div>
                    <div
                        className="col-lg-6 pt-4 pt-lg-0"
                        data-aos={"fade-up"}
                        data-aos-delay={"300"}
                    >
                        <p>
                            CV. Anugerah Terang Dunia telah berpengalaman selama
                            lebih dari empat tahun dalam bidang pertunjukkan dan
                            periklanan dengan media layar LED atau dikenal
                            dengan istilah videotron.
                        </p>
                        <p>
                            Puluhan klien puas dengan produk-produk kami
                            diantaranya Polda Kaltara, Dukcapil Kota Salatiga,
                            Pemerintah Kabupaten Blora, Gereja Bethany dan masih
                            banyak lainnya.
                        </p>
                        <Link to={"/tentang-kami"} className={"btn-learn-more"}>
                            Pelajari lebih lanjut
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
