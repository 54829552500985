import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBusinessTime } from "@fortawesome/free-solid-svg-icons/faBusinessTime";
import { faShieldAlt } from "@fortawesome/free-solid-svg-icons/faShieldAlt";
import { faAward } from "@fortawesome/free-solid-svg-icons/faAward";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons/faDollarSign";
import { faCloudSunRain } from "@fortawesome/free-solid-svg-icons/faCloudSunRain";
import { faBolt } from "@fortawesome/free-solid-svg-icons/faBolt";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faTv } from "@fortawesome/free-solid-svg-icons/faTv";
import { faWaveSquare } from "@fortawesome/free-solid-svg-icons/faWaveSquare";
import { faTools } from "@fortawesome/free-solid-svg-icons/faTools";
import { faCogs } from "@fortawesome/free-solid-svg-icons/faCogs";
import { faTree } from "@fortawesome/free-solid-svg-icons/faTree";
import "../../../styles/feature.css";

class Feature extends React.Component {
    render() {
        return (
            <section id="features" className="features">
                <Container>
                    <Col bsPrefix={"section-title"} data-aos={"fade-up"}>
                        <h2>Fitur</h2>
                        <p>Kenapa anda perlu menggunakan produk kami?</p>
                    </Col>
                    <Row data-aos={"fade-up"} data-aos-delay={300}>
                        <div className="col-lg-3 col-md-4">
                            <div className="icon-box">
                                <FontAwesomeIcon
                                    icon={faBusinessTime}
                                    style={{ color: "rgb(15,76,129)" }}
                                    fixedWidth={true}
                                />
                                <h3>Pengerjaan Cepat</h3>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 mt-4 mt-md-0">
                            <div className="icon-box">
                                <FontAwesomeIcon
                                    icon={faShieldAlt}
                                    style={{ color: "rgb(85,120,255)" }}
                                    fixedWidth={true}
                                />
                                <h3>Produk Bergaransi</h3>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 mt-4 mt-md-0">
                            <div className="icon-box">
                                <FontAwesomeIcon
                                    icon={faAward}
                                    style={{ color: "rgb(227,97,255)" }}
                                    fixedWidth={true}
                                />
                                <h3>Berkualitas</h3>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 mt-4 mt-lg-0">
                            <div className="icon-box">
                                <FontAwesomeIcon
                                    icon={faDollarSign}
                                    style={{ color: "rgb(133,187,101)" }}
                                    fixedWidth={true}
                                />
                                <h3>Harga Terjangkau</h3>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4 mt-4">
                            <div className="icon-box">
                                <FontAwesomeIcon
                                    icon={faCloudSunRain}
                                    style={{ color: "rgb(71,174,255)" }}
                                    fixedWidth={true}
                                />
                                <h3>Tahan Cuaca</h3>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 mt-4">
                            <div className="icon-box">
                                <FontAwesomeIcon
                                    icon={faBolt}
                                    style={{ color: "rgb(251,225,34)" }}
                                    fixedWidth={true}
                                />
                                <h3>Tahan Brownouts</h3>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 mt-4">
                            <div className="icon-box">
                                <FontAwesomeIcon
                                    icon={faSearch}
                                    style={{ color: "rgb(17,219,207)" }}
                                    fixedWidth={true}
                                />
                                <h3>Bebas Cacat Pixel</h3>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 mt-4">
                            <div className="icon-box">
                                <FontAwesomeIcon
                                    icon={faTv}
                                    style={{ color: "rgb(66,51,255)" }}
                                    fixedWidth={true}
                                />
                                <h3>Gambar Jernih</h3>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4 mt-4">
                            <div className="icon-box">
                                <FontAwesomeIcon
                                    icon={faWaveSquare}
                                    style={{ color: "rgb(0,255,132)" }}
                                    fixedWidth={true}
                                />
                                <h3>IC PWM Berkualitas</h3>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 mt-4">
                            <div className="icon-box">
                                <FontAwesomeIcon
                                    icon={faTools}
                                    style={{ color: "rgb(205,0,26)" }}
                                    fixedWidth={true}
                                />
                                <h3>Suku Cadang Terjamin</h3>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 mt-4">
                            <div className="icon-box">
                                <FontAwesomeIcon
                                    icon={faCogs}
                                    style={{ color: "rgb(72,73,75)" }}
                                    fixedWidth={true}
                                />
                                <h3>Dapat Dikustomisasi</h3>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 mt-4">
                            <div className="icon-box">
                                <FontAwesomeIcon
                                    icon={faTree}
                                    style={{ color: "rgb(76,187,23)" }}
                                    fixedWidth={true}
                                />
                                <h3>Ramah Lingkungan</h3>
                            </div>
                        </div>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default Feature;
