import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "../../../styles/showcase.css";

const Showcase = ({ prop }) => {
    return (
        <StaticQuery
            query={graphql`
                {
                    P125: file(
                        relativePath: {
                            eq: "showcase/P1.25 Indoor Showcase.jpg"
                        }
                    ) {
                        childImageSharp {
                            fluid(quality: 75) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    P153: file(
                        relativePath: {
                            eq: "showcase/P1.53 Indoor Showcase.jpg"
                        }
                    ) {
                        childImageSharp {
                            fluid(quality: 75) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    P183: file(
                        relativePath: {
                            eq: "showcase/P1.83 Indoor Showcase.jpg"
                        }
                    ) {
                        childImageSharp {
                            fluid(quality: 75) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    P186: file(
                        relativePath: {
                            eq: "showcase/P1.86 Indoor Showcase.jpg"
                        }
                    ) {
                        childImageSharp {
                            fluid(quality: 75) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    P1667: file(
                        relativePath: {
                            eq: "showcase/P1.667 Indoor Showcase.jpg"
                        }
                    ) {
                        childImageSharp {
                            fluid(quality: 75) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    P1904: file(
                        relativePath: {
                            eq: "showcase/P1.904 Indoor Showcase.jpg"
                        }
                    ) {
                        childImageSharp {
                            fluid(quality: 75) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    P25: file(
                        relativePath: {
                            eq: "showcase/P2.5 Indoor Showcase.jpg"
                        }
                    ) {
                        childImageSharp {
                            fluid(quality: 75) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    P30: file(
                        relativePath: {
                            eq: "showcase/P3.0 Indoor Showcase.jpg"
                        }
                    ) {
                        childImageSharp {
                            fluid(quality: 75) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    P4: file(
                        relativePath: { eq: "showcase/P4 Indoor Showcase.jpg" }
                    ) {
                        childImageSharp {
                            fluid(quality: 75) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    P5Indoor: file(
                        relativePath: { eq: "showcase/P5 Indoor Showcase.jpg" }
                    ) {
                        childImageSharp {
                            fluid(quality: 75) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    P5Outdoor: file(
                        relativePath: { eq: "showcase/P5 Outdoor Showcase.jpg" }
                    ) {
                        childImageSharp {
                            fluid(quality: 75) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    P6: file(
                        relativePath: { eq: "showcase/P6 Outdoor Showcase.jpg" }
                    ) {
                        childImageSharp {
                            fluid(quality: 75) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    P8: file(
                        relativePath: { eq: "showcase/P8 Outdoor Showcase.jpg" }
                    ) {
                        childImageSharp {
                            fluid(quality: 75) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    P10: file(
                        relativePath: {
                            eq: "showcase/P10 Outdoor Showcase.jpg"
                        }
                    ) {
                        childImageSharp {
                            fluid(quality: 75) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            `}
            render={data => (
                <section
                    id="more-services"
                    className="more-services section-bg"
                >
                    <Container>
                        <div className="section-title" data-aos="fade-up">
                            <h2>Produk-produk</h2>
                        </div>
                        <Row>
                            <Col md={6}>
                                <Link
                                    to={"/produk/indoor/P1.25"}
                                    aria-label={"Produk P1.25 Indoor"}
                                >
                                    <Img
                                        fluid={data.P125.childImageSharp.fluid}
                                    />
                                </Link>
                            </Col>
                            <Col md={6} className={"mt-4 mt-md-0"}>
                                <Link
                                    to={"/produk/indoor/P1.53"}
                                    aria-label={"Produk P1.53 Indoor"}
                                >
                                    <Img
                                        fluid={data.P153.childImageSharp.fluid}
                                    />
                                </Link>
                            </Col>
                        </Row>
                        <Row className={"mt-4"}>
                            <Col md={4}>
                                <Link
                                    to={"/produk/indoor/P1.86"}
                                    aria-label={"Produk P1.68 Indoor"}
                                >
                                    <Img
                                        fluid={data.P186.childImageSharp.fluid}
                                    />
                                </Link>
                            </Col>
                            <Col md={4} className={"mt-4 mt-md-0"}>
                                <Link
                                    to={"/produk/indoor/P1.667"}
                                    aria-label={"Produk P1.667 Indoor"}
                                >
                                    <Img
                                        fluid={data.P1667.childImageSharp.fluid}
                                    />
                                </Link>
                            </Col>
                            <Col md={4} className={"mt-4 mt-md-0"}>
                                <Link
                                    to={"/produk/indoor/P2.5"}
                                    aria-label={"Produk P2.5 Indoor"}
                                >
                                    <Img
                                        fluid={data.P25.childImageSharp.fluid}
                                    />
                                </Link>
                            </Col>
                        </Row>
                        <Row className={"mt-4"}>
                            <Col md={6}>
                                <Link
                                    to={"/produk/Outdoor/P5"}
                                    aria-label={"Produk P5 Outdoor"}
                                >
                                    <Img
                                        fluid={
                                            data.P5Outdoor.childImageSharp.fluid
                                        }
                                    />
                                </Link>
                            </Col>
                            <Col md={6} className={"mt-4 mt-md-0"}>
                                <Link
                                    to={"/produk/outdoor/P6"}
                                    aria-label={"Produk P6 Outdoor"}
                                >
                                    <Img
                                        fluid={data.P6.childImageSharp.fluid}
                                    />
                                </Link>
                            </Col>
                        </Row>
                        <Row className={"mt-4"}>
                            <Col md={6}>
                                <Link
                                    to={"/produk/outdoor/P8"}
                                    aria-label={"Produk P8 Outdoor"}
                                >
                                    <Img
                                        fluid={data.P8.childImageSharp.fluid}
                                    />
                                </Link>
                            </Col>
                            <Col md={6} className={"mt-4 mt-md-0"}>
                                <Link
                                    to={"/produk/outdoor/P10"}
                                    aria-label={"Produk P10 Outdoor"}
                                >
                                    <Img
                                        fluid={data.P10.childImageSharp.fluid}
                                    />
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </section>
            )}
        />
    );
};

export default Showcase;
