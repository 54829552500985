import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import BootstrapCarousel from "react-bootstrap/Carousel";
import "../../../styles/carousel.css";

const Carousel = () => {
    const data = useStaticQuery(graphql`
        fragment banner on File {
            childImageSharp {
                fluid(maxWidth: 2569) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        query CarouselQuery {
            picture0: file(relativePath: { eq: "carousel/carousel-1.jpg" }) {
                ...banner
            }
            picture1: file(relativePath: { eq: "carousel/carousel-2.jpg" }) {
                ...banner
            }
            picture2: file(relativePath: { eq: "carousel/carousel-3.jpg" }) {
                ...banner
            }
            picture3: file(relativePath: { eq: "carousel/carousel-4.jpg" }) {
                ...banner
            }
            picture4: file(relativePath: { eq: "carousel/carousel-5.jpg" }) {
                ...banner
            }
        }
    `);
    return (
        <section>
            <BootstrapCarousel wrap={true}>
                <BootstrapCarousel.Item>
                    <Img
                        fluid={data["picture0"].childImageSharp.fluid}
                        alt="Gambar carousel 1"
                    />
                    {/*<Carousel.Caption className={"text-left"}>*/}
                    {/*    <div>*/}
                    {/*        <h1>*/}
                    {/*            <span>Karena informasi anda berharga</span>*/}
                    {/*        </h1>*/}
                    {/*        <h2>*/}
                    {/*            <span>*/}
                    {/*                iLife membantu anda memenuhi kebutuhan akan*/}
                    {/*                pertunjukkan publik dengan layar LED.*/}
                    {/*            </span>*/}
                    {/*        </h2>*/}
                    {/*        <Link to={"/"} className={"btn-learn-more"}>*/}
                    {/*            Pelajari lebih lanjut*/}
                    {/*        </Link>*/}
                    {/*    </div>*/}
                    {/*</Carousel.Caption>*/}
                </BootstrapCarousel.Item>
                <BootstrapCarousel.Item>
                    <Img
                        fluid={data["picture1"].childImageSharp.fluid}
                        alt="Gambar carousel 2"
                    />
                    {/*<Carousel.Caption className={"text-right"}>*/}
                    {/*    <h1>Tampilan pertunjukan anda adalah segalanya</h1>*/}
                    {/*    <h2>*/}
                    {/*        Kami berkomitmen untuk memberikan pengalaman*/}
                    {/*        pertunjukan publik terbaik*/}
                    {/*    </h2>*/}
                    {/*    <Link to={"/"} className={"btn-learn-more"}>*/}
                    {/*        Pelajari lebih lanjut*/}
                    {/*    </Link>*/}
                    {/*</Carousel.Caption>*/}
                </BootstrapCarousel.Item>
                <BootstrapCarousel.Item>
                    <Img
                        fluid={data["picture2"].childImageSharp.fluid}
                        alt="Gambar carousel 3"
                    />
                    {/*<Carousel.Caption className={"text-left"}>*/}
                    {/*    <h1>Welcome 2</h1>*/}
                    {/*    <h2>Welcome welcome welcome</h2>*/}
                    {/*    <Link to={"/"} className={"btn-learn-more"}>*/}
                    {/*        Pelajari lebih lanjut*/}
                    {/*    </Link>*/}
                    {/*</Carousel.Caption>*/}
                </BootstrapCarousel.Item>
                <BootstrapCarousel.Item>
                    <Img
                        fluid={data["picture3"].childImageSharp.fluid}
                        alt="Gambar carousel 4"
                    />
                    {/*<Carousel.Caption className={"text-right"}>*/}
                    {/*    <div>*/}
                    {/*        <h1>*/}
                    {/*            <span>Setiap pixel indah</span>*/}
                    {/*        </h1>*/}
                    {/*        <h2>*/}
                    {/*            <span>*/}
                    {/*                iLife menyadari bahwa setiap pixel anda*/}
                    {/*                harus tertampil sempurna.*/}
                    {/*            </span>*/}
                    {/*        </h2>*/}
                    {/*        <Link to={"/"} className={"btn-learn-more"}>*/}
                    {/*            Pelajari lebih lanjut*/}
                    {/*        </Link>*/}
                    {/*    </div>*/}
                    {/*</Carousel.Caption>*/}
                </BootstrapCarousel.Item>
                <BootstrapCarousel.Item>
                    <Img
                        fluid={data["picture4"].childImageSharp.fluid}
                        alt="Gambar carousel 5"
                    />
                    {/*<Carousel.Caption className={"text-right"}>*/}
                    {/*    <div>*/}
                    {/*        <h1>*/}
                    {/*            <span>Setiap pixel indah</span>*/}
                    {/*        </h1>*/}
                    {/*        <h2>*/}
                    {/*            <span>*/}
                    {/*                iLife menyadari bahwa setiap pixel anda*/}
                    {/*                harus tertampil sempurna.*/}
                    {/*            </span>*/}
                    {/*        </h2>*/}
                    {/*        <Link to={"/"} className={"btn-learn-more"}>*/}
                    {/*            Pelajari lebih lanjut*/}
                    {/*        </Link>*/}
                    {/*    </div>*/}
                    {/*</Carousel.Caption>*/}
                </BootstrapCarousel.Item>
            </BootstrapCarousel>
        </section>
    );
};

export default Carousel;
