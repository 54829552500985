import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React from "react";
import PropTypes from "prop-types";

const GoogleMaps = props => (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Peta Lokasi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h4>Failed to load map</h4>
            <p>Anda perlu berlangganan Google Maps javascript API sebelum dapat menggunakan fitur ini. Hubungi
                webmaster.</p>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={props.onHide}>Tutup</Button>
        </Modal.Footer>
    </Modal>
);

GoogleMaps.propTypes = {
    onHide: PropTypes.func,
    show: PropTypes.bool
};


export default GoogleMaps;