import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Client5 from "../../../images/clients/client-clique-cafe.png";
import Client4 from "../../../images/clients/client-dukcapil-salatiga.png";
import Client3 from "../../../images/clients/client-gereja-bethany-salatiga.png";
import Client2 from "../../../images/clients/client-gereja-isa-almasih.png";
import Client6 from "../../../images/clients/client-pemkot-blora.png";
import Client1 from "../../../images/clients/client-polda-kaltara.png";
import "../../../styles/clients.css";

const Client = () => {
    // const data = useStaticQuery(graphql`
    //     fragment clients on File{
    //         childImageSharp{
    //             fixed(width: 100){
    //                 ...GatsbyImageSharpFixed
    //             }
    //         }
    //     }
    //     query ClientImageQuery {
    //         pic1: file(
    //             relativePath: { eq: "clients/client-polda-kaltara.png" }
    //         ) {
    //            ...clients
    //         }
    //         pic2: file(
    //             relativePath: { eq: "clients/client-gereja-isa-almasih.png" }
    //         ) {
    //             ...clients
    //         }
    //         pic3: file(
    //             relativePath: {
    //                 eq: "clients/client-gereja-bethany-salatiga.png"
    //             }
    //         ) {
    //             ...clients
    //         }
    //         pic4: file(relativePath: { eq: "clients/client-dukcapil-salatiga.png" }) {
    //             ...clients
    //         }
    //         pic5: file(
    //             relativePath: { eq: "clients/client-clique-cafe.png" }
    //         ) {
    //             ...clients
    //         }
    //         pic6: file(
    //             relativePath: { eq: "clients/client-pemkot-blora.png" }
    //         ) {
    //             ...clients
    //         }
    //     }
    // `);
    return (
        <>
            <section id="clients" className="clients">
                <Container data-aos={"zoom-in"}>
                    <Row>
                        <Col lg={2} md={4}>
                            <a
                                href={"https://kaltara.polri.go.id/"}
                                aria-label={"Ke Polda Kaltara"}
                                target={"_blank"}
                                rel={"noreferrer noopener"}
                            >
                                <img
                                    src={Client1}
                                    className="img-fluid"
                                    alt={"Logo Polda Kaltara"}
                                />
                            </a>
                        </Col>
                        <Col lg={2} md={4}>
                            <a
                                href={"https://sinodegia.or.id/"}
                                aria-label={"Ke Gereja Isa Almasih"}
                                target={"_blank"}
                                rel={"noreferrer noopener"}
                            >
                                <img
                                    src={Client2}
                                    className="img-fluid"
                                    alt="Logo Gereja Isa Almasih"
                                />
                            </a>
                        </Col>
                        <Col lg={2} md={4}>
                            <img
                                src={Client3}
                                className="img-fluid"
                                alt="Logo Gereja Bethany Salatiga"
                            />
                        </Col>
                        <Col lg={2} md={4}>
                            <a
                                href={"https://disdukcapil.salatiga.go.id/"}
                                aria-label={
                                    "Kantor Dinas Kependudukan Dan Catatan Sipil Kota Salatiga"
                                }
                                target={"_blank"}
                                rel={"noreferrer noopener"}
                            >
                                <img
                                    src={Client4}
                                    className="img-fluid"
                                    alt="Logo Dukcapil Salatiga"
                                />
                            </a>
                        </Col>
                        <Col lg={2} md={4}>
                            <a
                                href={
                                    "https://www.zomato.com/jakarta/clique-kitchen-bar-serpong-utara-tangerang"
                                }
                                aria-label={"Ke Web Zomato Clique Cafe"}
                                target={"_blank"}
                                rel={"noreferrer noopener"}
                            >
                                <img
                                    src={Client5}
                                    className="img-fluid"
                                    alt="Logo Clique Cafe Jakarta"
                                />
                            </a>
                        </Col>
                        <Col lg={2} md={4}>
                            <a
                                href={"https://www.blorakab.go.id/"}
                                aria-label={"Ke Pemerintah Kabupaten Blora"}
                                target={"_blank"}
                                rel={"noreferrer noopener"}
                            >
                                <img
                                    src={Client6}
                                    className="img-fluid"
                                    alt="Logo Pemerintah Kota Blora"
                                />
                            </a>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/*TODO Fix the clients using gatsby js*/}
            {/*<section id="gatsby-image-clients" className="clients">*/}
            {/*    <Container>*/}
            {/*        <Row>*/}
            {/*            <Col lg={2} md={4}>*/}
            {/*                /!*<Image fluid={data.pic1.childImageSharp.fluid}></Image>*!/*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*    </Container>*/}
            {/*</section>*/}
        </>
    );
};

export default Client;
