import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

class Formulir extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formValues: {
                name: "",
                email: "",
                subject: "",
                message: "",
            },
            formErrors: {
                name: "",
                email: "",
                subject: "",
                message: "",
            },
            formValidity: {
                name: false,
                email: false,
                subject: false,
                message: false,
            },
            isSubmitting: false,
        };
    }

    handleChange = ({ target }) => {
        const { formValues } = this.state;
        formValues[target.name] = target.value;
        this.setState({ formValues });
        this.handleValidation(target);
    };

    handleValidation = target => {
        const { name, value } = target;
        const fieldValidationErrors = this.state.formErrors;
        const validity = this.state.formValidity;
        const isName = name === "name";
        const isEmail = name === "email";
        const isSubject = name === "subject";
        const isMessage = name === "message";

        const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        validity[name] = value.length > 0;
        fieldValidationErrors[name] = validity[name]
            ? ""
            : `${name} dibutuhkan dan tidak dapat kosong`;

        if (validity[name]) {
            if (isEmail) {
                validity[name] = emailTest.test(value);
                fieldValidationErrors[name] = validity[name]
                    ? ""
                    : `${name} harus alamat email yang valid`;
            }
            if (isSubject) {
                validity[name] = value.length >= 3;
                fieldValidationErrors[name] = validity[name]
                    ? ""
                    : `${name} should be 3 characters minimum`;
            }
        }

        this.setState({
            formErrors: fieldValidationErrors,
            formValidity: validity,
        });
    };

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ isSubmitting: true });
        const { formValues, formValidity } = this.state;
        if (Object.values(formValidity).every(Boolean)) {
            alert("Form is validated! Submitting the form...");
            this.setState({ isSubmitting: false });
        } else {
            for (let key in formValues) {
                let target = {
                    name: key,
                    value: formValues[key],
                };
                this.handleValidation(target);
            }
            this.setState({ isSubmitting: false });
        }
    };
    render() {
        const { formValues, formErrors, isSubmitting } = this.state;
        return (
            <Form
                className="contact-email-form"
                method="post"
                netlify-honeypot="bot-field"
                data-netlify="true"
                onSubmit={this.handleSubmit}
            >
                <Form.Row>
                    <Col>
                        <Form.Group controlId={"name"}>
                            <Form.Label srOnly={true}>Name</Form.Label>
                            <Form.Control
                                type={"text"}
                                name={"name"}
                                placeholder={"Nama"}
                                className={`form-control ${
                                    formErrors.name ? "is-invalid" : ""
                                }`}
                                onChange={this.handleChange}
                                value={formValues.name}
                            />
                            <div className="invalid-feedback">
                                {formErrors.name}
                            </div>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId={"email"}>
                            <Form.Label srOnly={true}>Email</Form.Label>
                            <Form.Control
                                type={"email"}
                                name={"email"}
                                placeholder={"Email"}
                                className={`form-control ${
                                    formErrors.email ? "is-invalid" : ""
                                }`}
                                onChange={this.handleChange}
                                value={formValues.email}
                            />
                            <div className="invalid-feedback">
                                {formErrors.email}
                            </div>
                        </Form.Group>
                    </Col>
                </Form.Row>

                <Form.Group controlId={"subject"}>
                    <Form.Label srOnly={true}>Name</Form.Label>
                    <Form.Control
                        type={"text"}
                        name={"subject"}
                        placeholder={"Subjek"}
                        className={`form-control ${
                            formErrors.subject ? "is-invalid" : ""
                        }`}
                        onChange={this.handleChange}
                        value={formValues.subject}
                    />
                    <div className="invalid-feedback">{formErrors.subject}</div>
                </Form.Group>
                <Form.Group controlId={"message"}>
                    <Form.Label srOnly={true}>Message</Form.Label>
                    <Form.Control
                        as={"textarea"}
                        name={"message"}
                        placeholder={"Pesan"}
                        className={`form-control ${
                            formErrors.message ? "is-invalid" : ""
                        }`}
                        onChange={this.handleChange}
                        value={formValues.message}
                    />
                    <div className="invalid-feedback">{formErrors.message}</div>
                </Form.Group>
                <Button
                    type={"submit"}
                    disabled={isSubmitting}
                    variant={"primary btn-block"}
                >
                    {isSubmitting ? "Please wait..." : "Submit"}
                </Button>
            </Form>
        );
    }
}

export default Formulir;
