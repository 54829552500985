import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import GoogleMaps from "./map";
import "../../../styles/contact.css";
import Formulir from "./contactForm";

const Contact = () => {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <section id="contact" className="contact section-bg">
            <Container>
                <div
                    className="section-title"
                    data-aos="fade-up"
                    data-aos-once={true}
                >
                    <h2>Hubungi kami</h2>
                    <p>
                        Tertarik dengan produk dan jasa iLife? Langsung hubungi
                        kami.
                    </p>
                </div>
                <Row>
                    <Col>
                        <Row data-aos={"fade-up"} data-aos-delay={1000}>
                            <Col>
                                <div
                                    className="info-box"
                                    role={"button"}
                                    tabIndex={0}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setModalShow(true)}
                                    onKeyDown={() => setModalShow(true)}
                                >
                                    <FontAwesomeIcon
                                        icon={faMapMarkerAlt}
                                        fixedWidth={true}
                                    />
                                    <h3>Alamat kami</h3>
                                    <p>
                                        Jl. Kol. Sunandar No. 31, Blora, Jawa
                                        Tengah 58214
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        <Row data-aos={"fade-up"} data-aos-delay={1000}>
                            <Col>
                                <a
                                    href={"mailto:info@ilife.id"}
                                    aria-label={"Email"}
                                >
                                    <div className="info-box mt-4">
                                        <FontAwesomeIcon
                                            icon={faEnvelope}
                                            fixedWidth={true}
                                        />
                                        <h3>Email</h3>
                                        <p>info@ilife.id</p>
                                    </div>
                                </a>
                            </Col>
                            <Col>
                                <a
                                    href={"tel:+622965301042"}
                                    aria-label={"Telephone"}
                                >
                                    <div className="info-box mt-4">
                                        <FontAwesomeIcon
                                            icon={faPhone}
                                            fixedWidth={true}
                                        />
                                        <h3>Telepon Kantor</h3>
                                        <p>+622965301042</p>
                                    </div>
                                </a>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row data-aos={"fade-up"} data-aos-delay={1000}>
                            <Col>
                                <Formulir />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <GoogleMaps
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </Container>
        </section>
    );
};

export default Contact;
