import React from "react";
import Cailiang from "../../../images/brands/brand-cailiang.png";
import Colorlight from "../../../images/brands/brand-colorlight.png";
import Gkgd from "../../../images/brands/brand-gkgd.png";
import Novastar from "../../../images/brands/brand-novastar.png";
import Nationstar from "../../../images/brands/brand-nationstar.png";
import Meanwell from "../../../images/brands/brand-meanwell.png";
import Helilai from "../../../images/brands/brand-heilai.png";
import Huidu from "../../../images/brands/brand-huidu.png";
import Listenvision from "../../../images/brands/brand-listen-vision.png";
import Ledvisual from "../../../images/brands/brand-ledvisual.png";
import Qiangli from "../../../images/brands/brand-qiangli.png";
import Rgblink from "../../../images/brands/brand-rgblink.png";
import Vdwall from "../../../images/brands/brand-vdwall.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../../styles/brands.css";

class Brand extends React.Component {
    render() {
        return (
            <section id="brands" className="brands">
                <Container>
                    <div
                        className="section-title"
                        data-aos="fade-up"
                        data-aos-once={true}
                    >
                        <h2>Merek Produk</h2>
                        <p>
                            Berikut adalah merek-merek layar LED beserta
                            komponen pendukungnya yang dipakai dalam proyek dan
                            kami jual.
                        </p>
                    </div>
                    <Row
                        data-aos={"fade-up"}
                        data-aos-delay={0}
                        data-aos-duration="1500"
                        data-aos-once={true}
                    >
                        <Col lg={2} md={4}>
                            <a
                                href={"https://vdwall.cn/"}
                                aria-label={"Ke Shenzhen VDWALL Co., Ltd."}
                                target={"_blank"}
                                rel={"noreferrer noopener"}
                            >
                                <img
                                    src={Vdwall}
                                    className="img-fluid"
                                    title={"Shenzhen VDWALL"}
                                    alt={"Logo VDWALL"}
                                />
                            </a>
                        </Col>
                        <Col lg={2} md={4}>
                            <a
                                href={"https://cailiangled.vn/"}
                                aria-label={
                                    "Ke Quanzhou Cailiang Electronics Co., Ltd."
                                }
                                target={"_blank"}
                                rel={"noreferrer noopener"}
                            >
                                <img
                                    src={Cailiang}
                                    className="img-fluid"
                                    title={"Quanzhou Cailiang Electronics"}
                                    alt="Logo Cailiang"
                                />
                            </a>
                        </Col>
                        <Col lg={2} md={4}>
                            <a
                                href={"http://en.listenvision.cn/"}
                                aria-label={
                                    "Ke Shanghai Listen Vision Technology Inc."
                                }
                                target={"_blank"}
                                rel={"noreferrer noopener"}
                            >
                                <img
                                    src={Listenvision}
                                    className="img-fluid"
                                    title={"Shanghai Listen Vision Technology"}
                                    alt="Logo Listenvision"
                                />
                            </a>
                        </Col>
                        <Col lg={2} md={4}>
                            <a
                                href={"https://www.novastar.tech/"}
                                aria-label={"Ke Huidu Technology. Co., Ltd."}
                                target={"_blank"}
                                rel={"noreferrer noopener"}
                            >
                                <img
                                    src={Huidu}
                                    className="img-fluid"
                                    title={"Huidu Technology."}
                                    alt="Logo Huidu."
                                />
                            </a>
                        </Col>
                        <Col lg={2} md={4}>
                            <a
                                href={"http://www.lednets.com/"}
                                aria-label={
                                    "Ke Colorlight Cloud Technology Co., Ltd."
                                }
                                target={"_blank"}
                                rel={"noreferrer noopener"}
                            >
                                <img
                                    src={Colorlight}
                                    className="img-fluid"
                                    title={"Colorlight Cloud Technology"}
                                    alt="Logo Colorlight"
                                />
                            </a>
                        </Col>
                        <Col lg={2} md={4}>
                            <a
                                href={"http://www.gkgd.com/en/"}
                                aria-label={
                                    "Ke Shanxi High-tech Huaye Electronic Group Co., Ltd."
                                }
                                target={"_blank"}
                                rel={"noreferrer noopener"}
                            >
                                <img
                                    src={Gkgd}
                                    className="img-fluid"
                                    title={"Shanxi High-tech Huaye Electronic"}
                                    alt="Logo Gkgd"
                                />
                            </a>
                        </Col>
                    </Row>
                    <Row
                        data-aos={"fade-up"}
                        data-aos-delay={1000}
                        data-aos-duration="1500"
                        data-aos-once={true}
                    >
                        <Col lg={2} md={4}>
                            <a
                                href={"http://www.qiangliled.com/"}
                                aria-label={
                                    "Ke Xiamen Qiangli Jucai Opto-Electronic Technology Co., Ltd."
                                }
                                target={"_blank"}
                                rel={"noreferrer noopener"}
                            >
                                <img
                                    src={Qiangli}
                                    className="img-fluid"
                                    title={"Qiangli Jucai Opto-Electronic"}
                                    alt={"Logo Qiangli"}
                                />
                            </a>
                        </Col>
                        <Col lg={2} md={4}>
                            <a
                                href={"https://www.novastar.tech/"}
                                aria-label={"Ke Xi'an Novastar Tech Co., Ltd."}
                                target={"_blank"}
                                rel={"noreferrer noopener"}
                            >
                                <img
                                    src={Novastar}
                                    className="img-fluid"
                                    title={"Xi'an Novastar Tech"}
                                    alt="Logo Novastar"
                                />
                            </a>
                        </Col>
                        <Col lg={2} md={4}>
                            <a
                                href={"https://www.leds-visual.com/"}
                                aria-label={
                                    "Ke Shenzhen LED Visual Photoelectric Co., Ltd."
                                }
                                target={"_blank"}
                                rel={"noreferrer noopener"}
                            >
                                <img
                                    src={Ledvisual}
                                    className="img-fluid"
                                    title={"Shenzhen LED Visual Photoelectric"}
                                    alt="Logo Led visual"
                                />
                            </a>
                        </Col>
                        <Col lg={2} md={4}>
                            <a
                                href={"http://www.nationstar.com/"}
                                aria-label={
                                    "Ke Foshan Nationstar Opto-Electronics Co., Ltd."
                                }
                                target={"_blank"}
                                rel={"noreferrer noopener"}
                            >
                                <img
                                    src={Nationstar}
                                    className="img-fluid"
                                    title={"Foshan Nationstar Opto-Electronics"}
                                    alt="Logo Nationstar"
                                />
                            </a>
                        </Col>
                        <Col lg={2} md={4}>
                            <a
                                href={"https://rgblink.com/"}
                                aria-label={
                                    "Ke Xiamen RGBlink Science & Technology Co., Ltd."
                                }
                                target={"_blank"}
                                rel={"noreferrer noopener"}
                            >
                                <img
                                    src={Rgblink}
                                    className="img-fluid"
                                    title={
                                        "Xiamen RGBlink Science & Technology"
                                    }
                                    alt="Logo Colorlight"
                                />
                            </a>
                        </Col>
                        <Col lg={2} md={4}>
                            <a
                                href={"http://www.hll-ledscreens.com/"}
                                aria-label={
                                    "Ke Shenzhen Helilai Technology Co.,Ltd."
                                }
                                target={"_blank"}
                                rel={"noreferrer noopener"}
                            >
                                <img
                                    src={Helilai}
                                    className="img-fluid"
                                    title={"Shenzhen Helilai Technology"}
                                    alt={"Logo Helilai"}
                                />
                            </a>
                        </Col>
                    </Row>
                    <Row
                        data-aos={"fade-up"}
                        data-aos-delay={2000}
                        data-aos-duration="1500"
                        data-aos-once={true}
                    >
                        <Col />
                        <Col />
                        <Col>
                            <a
                                href={"https://www.meanwell.com/"}
                                aria-label={
                                    "Ke Mean Well Enterprises Co., Ltd."
                                }
                                target={"_blank"}
                                rel={"noreferrer noopener"}
                            >
                                <img
                                    src={Meanwell}
                                    className="img-fluid"
                                    title={"Mean Well Enterprises"}
                                    alt="Logo Meanwell"
                                />
                            </a>
                        </Col>
                        <Col />
                        <Col />
                    </Row>
                </Container>
            </section>
        );
    }
}

export default Brand;
